"use client";
import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface ClientSwiperSectionProps {
	spaceBetween?: number;
	slidesPerView?: number;
	loop?: boolean;
	breakpoints?: any;
	items: {
		imgLink: string;
		label: string;
	}[];
}

const ClientSwiperSection: FC<ClientSwiperSectionProps> = ({
   spaceBetween = 30,
   slidesPerView = 2,
   loop = true,
   breakpoints = {
       576: { slidesPerView: 2 },
       768: { slidesPerView: 3 },
       992: { slidesPerView: 4 },
       1200: { slidesPerView: 6 },
   },
   items,
}) => {
	return (
		<Swiper
			spaceBetween={spaceBetween}
			slidesPerView={slidesPerView}
			breakpoints={breakpoints}
			loop={loop}
		>
			{items.map((item, index) => (
				<SwiperSlide className="swiper-slide" key={index}>

					<picture>
						<img src={item.imgLink} className="grayscale px-sm-4 ps-0" alt={item.label} />
					</picture>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default ClientSwiperSection;